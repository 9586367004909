import React from "react"
import {
    TransitionGroup,
    Transition as ReactTransition,
} from "react-transition-group"

const timeout = 500
const getTransitionStyles = {
    entering: {
        position: 'absolute',
        opacity: 0,
    },
    entered: {
        transition: `opacity ${timeout}ms ease-in-out`,
        opacity: 1,
    },
    exiting: {
        transition: `all ${timeout}ms ease-in-out`,
        opacity: 0
    },
}
class Transition extends React.PureComponent {
    onEnter = () => {
        const root = document.documentElement.style;
        this.prevBehavior = root.getPropertyValue('scroll-behavior');
        root.setProperty('scroll-behavior', 'auto');
    }

    onEntered = () => {
        window.scrollTo(0, 0);
        document.documentElement.style.setProperty('scroll-behavior', this.prevBehavior);
    }

    render() {
        const { children, location } = this.props

        return (
            <TransitionGroup>
                <ReactTransition
                    key={location.pathname}
                    timeout={{
                        enter: timeout,
                        exit: timeout,
                    }}
                    onEnter={this.onEnter}
                    onEntered={this.onEntered}
                >
                    {status => (
                        <div
                            style={{
                                ...getTransitionStyles[status],
                            }}
                        >
                            {children}
                        </div>
                    )}
                </ReactTransition>
            </TransitionGroup>
        )
    }
}

export default Transition
