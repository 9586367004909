import React from 'react';
import PropTypes from 'prop-types';
import { createLocaleTextGetter } from '../util';

function localize(Component) {
    const LocalizedComponent = (props) => {
        const localizedData = createLocaleTextGetter(props.pageContext?.locale)(props.data);

        return <Component {...props} data={localizedData} />;
    };

    LocalizedComponent.propTypes = {
        pageContext: PropTypes.shape({
            locale: PropTypes.string,
        }),
        data: PropTypes.object,
    };

    return LocalizedComponent;
}

export default localize;
